@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600&family=Playfair+Display:wght@400;600&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
#root,
.app {
  height: 100%;
  background-color: #010026;
  @apply text-white;
}

textarea:focus, input:focus, select:focus {
  box-shadow: 0 0 0 0;
  border: 0 none;
  outline: 0;
} 